/* eslint no-var: "off" */
// var is used to resolve ?.
export function getRecordBoolFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allBoolValues.find(boolVal => boolVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.value;
}
export function getRecordDateFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allDateValues.find(dateVal => dateVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.value;
}
export function getRecordFileFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allFileValues.find(fileVal => fileVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.value;
}
export function getRecordGeoFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allGeoValues.find(fileVal => fileVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.value;
}
export function getRecordListLookupFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allListLookupValues.find(fileVal => fileVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.lookups;
}
export function getRecordLookupFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allLookupValues.find(lookupVal => lookupVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.lookup;
}
export function getRecordNumberFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allNumberValues.find(boolVal => boolVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.valueFloat;
}
export function getRecordStrFieldValueByIdentifier(identifier, record) {
    var _a;
    return (_a = record.allStringValues.find(strVal => strVal.identifier === identifier)) === null || _a === void 0 ? void 0 : _a.value;
}
