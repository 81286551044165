import { IonButton, IonInput, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { documentOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, h, ref } from 'vue';
export default defineComponent({
    props: {
        fileName: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        }
    },
    emits: ['llFile'],
    setup(props, { emit }) {
        const inputRef = ref();
        const fileName = ref(props.fileName);
        function handleNewInput() {
            inputRef.value.click();
        }
        function handleFileInput(evt) {
            const input = evt.target;
            const file = input.files[0];
            fileName.value = file.name;
            emit('llFile', file);
        }
        function handleClearInput() {
            fileName.value = '';
            emit('llFile', undefined);
        }
        return () => [
            h(IonItem, { lines: 'none ' }, () => [
                h('input', {
                    type: 'file',
                    ref: inputRef,
                    hidden: true,
                    onChange: handleFileInput
                }),
                h(IonLabel, { position: 'stacked' }, () => [
                    props.label
                ]),
                h(IonInput, { value: fileName.value, key: fileName.value }),
            ]),
            h(IonItem, {}, () => [
                h(IonLabel, { position: 'stacked' }, () => []),
                h(IonButton, {
                    slot: 'end',
                    onClick: handleNewInput,
                    fill: 'clear',
                }, () => [
                    h(IonIcon, {
                        icon: documentOutline,
                    })
                ]),
                h(IonButton, {
                    slot: 'end',
                    onClick: handleClearInput,
                    fill: 'clear'
                }, () => [
                    h(IonIcon, {
                        icon: closeOutline,
                    })
                ])
            ]),
        ];
    }
});
