import { defineComponent, h, ref, onMounted, watch } from 'vue';
import { IonItemGroup, IonItem, IonLabel, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonList, IonPage, IonSearchbar, IonToolbar, modalController, IonItemDivider } from '@ionic/vue';
import env from '../utils/env';
import { Loader } from '@googlemaps/js-api-loader';
import { handleGeoInput } from './Record';
import { isDark } from '../services/theme';
import { buildOutline, location } from 'ionicons/icons';
let counter = 0;
let loadedMaps = false;
export async function startGeoModal(initialValue, identifier, recordRef, infoRef) {
    const GeoModal = defineComponent({
        setup() {
            let autocompleteService;
            const searchValue = ref(initialValue);
            const autoCompletePredictions = ref([]);
            let debounceTimeout;
            watch(searchValue, async () => {
                const tmpSearchValue = searchValue.value;
                if (tmpSearchValue.length > 2) {
                    if (debounceTimeout)
                        clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                        autocompleteService === null || autocompleteService === void 0 ? void 0 : autocompleteService.getPlacePredictions({
                            input: tmpSearchValue
                        }, async (predictions, status) => {
                            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                                console.error('Hier kann ein Fehler auftreten');
                                return;
                            }
                            autoCompletePredictions.value = predictions !== null && predictions !== void 0 ? predictions : [];
                            console.log(`searched for ${tmpSearchValue} and found ${autoCompletePredictions.value.length} predictions`);
                        });
                    }, 350);
                }
            });
            onMounted(async () => {
                if (window.navigator.onLine && !loadedMaps) {
                    console.log('[MAPS] init google maps');
                    const loader = new Loader({
                        apiKey: env.googleApiKey,
                        libraries: [
                            'places',
                        ],
                    });
                    await loader.load();
                    loadedMaps = true;
                    console.log('[MAPS] finished initialization google maps');
                }
                autocompleteService = new window.google.maps.places.AutocompleteService();
            });
            return () => (h(IonPage, {}, () => [
                h(IonHeader, () => [
                    h(IonToolbar, () => [
                        h(IonSearchbar, {
                            value: initialValue,
                            onIonInput: (evt) => {
                                searchValue.value = evt.target.value;
                            },
                        }),
                        h(IonButtons, {
                            slot: 'end',
                            onClick: async () => (await modalController.getTop()).dismiss()
                        }, () => [
                            h(IonButton, { fill: 'clear' }, () => [
                                'Abbrechen'
                            ])
                        ]),
                    ])
                ]),
                h(IonContent, () => [
                    h(IonList, () => [...autoCompletePredictions.value.map((prediction) => (h(IonItem, {
                            button: true,
                            onClick: async () => {
                                (await modalController.getTop()).dismiss([prediction.description, prediction.place_id], 'success');
                            },
                        }, () => [
                            h(IonIcon, {
                                icon: location,
                            }),
                            prediction.description,
                        ]))),
                        h(IonItemDivider, () => [
                            h(IonImg, {
                                src: isDark
                                    ? './img/powered_by_google/ios/powered_by_google_on_non_white.png'
                                    : './img/powered_by_google/ios/powered_by_google_on_white.png'
                            })
                        ])
                    ])
                ])
            ]));
        }
    });
    const modalCtrl = await modalController.create({
        component: GeoModal,
    });
    await modalCtrl.present();
    const dismissResults = await modalCtrl.onDidDismiss();
    if (dismissResults.role === 'success') {
        const geoCoder = new google.maps.Geocoder();
        if (dismissResults.data[1]) {
            geoCoder.geocode({
                placeId: dismissResults.data[1],
            }, (geocoderResults, geocoderStatus) => {
                if (geocoderStatus !== google.maps.GeocoderStatus.OK || geocoderResults.length === 0) {
                    console.error('Es ist ein Geocoding Fehler aufgetreten');
                    return;
                }
                const geoValue = {
                    lat: geocoderResults[0].geometry.location.lat(),
                    lng: geocoderResults[0].geometry.location.lng(),
                    info: geocoderResults[0].formatted_address,
                };
                ++counter;
                handleGeoInput(geoValue, identifier, recordRef);
                infoRef.value = geoValue.info;
            });
        }
    }
}
export default defineComponent({
    props: {
        label: String,
        info: String,
        identifier: String,
        latLng: {
            type: Object,
        },
        recordRef: {
            type: Object,
        }
    },
    setup(props) {
        const currentLatLng = ref(props.latLng);
        const currentInfo = ref(props.info);
        const detailView = ref(false);
        return () => {
            if (detailView.value) {
                return [
                    h(IonItem, () => [
                        h(IonLabel, {}, () => [props.label])
                    ]),
                    h(IonItem, () => [
                        h(IonLabel, { position: 'stacked' }, () => [
                            'Info'
                        ]),
                        h(IonInput, {
                            value: currentInfo.value,
                            onIonInput: (evt) => {
                                var _a, _b;
                                const newInfoValue = evt.target.value;
                                const geoVal = {
                                    lat: (_a = currentLatLng.value) === null || _a === void 0 ? void 0 : _a[0],
                                    lng: (_b = currentLatLng.value) === null || _b === void 0 ? void 0 : _b[1],
                                    info: newInfoValue,
                                };
                                handleGeoInput(geoVal, props.identifier, props.recordRef);
                                currentInfo.value = newInfoValue;
                            }
                        }),
                    ]),
                    h(IonItem, () => {
                        var _a;
                        return [
                            h(IonLabel, { position: 'stacked' }, () => [
                                'Lat'
                            ]),
                            h(IonInput, {
                                value: (_a = props.latLng) === null || _a === void 0 ? void 0 : _a[0],
                                onIonInput: (evt) => {
                                    var _a, _b;
                                    const newLatValue = Number(evt.target.value) || 0;
                                    const geoVal = {
                                        lat: newLatValue,
                                        lng: (_a = currentLatLng.value) === null || _a === void 0 ? void 0 : _a[1],
                                        info: currentInfo.value,
                                    };
                                    handleGeoInput(geoVal, props.identifier, props.recordRef);
                                    currentLatLng.value = [newLatValue, (_b = currentLatLng.value) === null || _b === void 0 ? void 0 : _b[1]];
                                }
                            }),
                        ];
                    }),
                    h(IonItem, () => {
                        var _a;
                        return [
                            h(IonLabel, { position: 'stacked' }, () => [
                                'Long'
                            ]),
                            h(IonInput, {
                                value: (_a = props.latLng) === null || _a === void 0 ? void 0 : _a[1],
                                onIonInput: (evt) => {
                                    var _a, _b;
                                    const newLngValue = Number(evt.target.value) || 0;
                                    const geoVal = {
                                        lat: (_a = currentLatLng.value) === null || _a === void 0 ? void 0 : _a[0],
                                        lng: newLngValue,
                                        info: currentInfo.value,
                                    };
                                    handleGeoInput(geoVal, props.identifier, props.recordRef);
                                    currentLatLng.value = [(_b = currentLatLng.value) === null || _b === void 0 ? void 0 : _b[0], newLngValue];
                                }
                            }),
                        ];
                    }),
                    h(IonItem, () => [
                        h(IonLabel),
                        h(IonButton, {
                            slot: 'end',
                            fill: 'clear',
                            onClick: () => detailView.value = !detailView.value,
                        }, () => [
                            h(IonIcon, {
                                icon: buildOutline,
                            })
                        ])
                    ]),
                ];
            }
            else {
                return h(IonItemGroup, () => [
                    h(IonItem, {}, () => [
                        h(IonLabel, { position: 'stacked' }, () => [props.label]),
                        h(IonInput, {
                            key: counter,
                            value: currentInfo.value,
                            onClick: () => startGeoModal(currentInfo.value, props.identifier, props.recordRef, currentInfo),
                        }),
                        h(IonButton, {
                            fill: 'clear',
                            slot: 'end',
                            onClick: () => detailView.value = !detailView.value,
                        }, () => [
                            h(IonIcon, {
                                icon: buildOutline,
                            })
                        ])
                    ]),
                    h(IonItemDivider, () => [
                        h(IonImg, {
                            slot: 'end',
                            src: isDark
                                ? './img/powered_by_google/ios/powered_by_google_on_non_white.png'
                                : './img/powered_by_google/ios/powered_by_google_on_white.png'
                        })
                    ])
                ]);
            }
        };
    }
});
