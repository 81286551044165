import { IonCard, IonCardContent, IonItem, IonLabel, IonInput, IonButton, IonTextarea, IonIcon, IonToggle, IonDatetime, IonSelect, IonSelectOption, IonImg, IonItemDivider, IonItemGroup, alertController, loadingController, } from '@ionic/vue';
import { cloudOfflineOutline, cloudCircleOutline } from 'ionicons/icons';
import { defineComponent, h, ref, toRef, onMounted, computed } from 'vue';
import Fileupload from './Fileupload';
import GeoInput from './GeoInput';
import { updateRecord } from '../services/db/records';
import { storeFile, storeRecordMutation, getRecordMutation, removeRecordMutation, } from '../services/db/recordmutations';
import { deleteRecord, } from '../services/db/records';
import { mutateRecord, deleteRecordMutation } from '../services/graphql';
import { getRecordBoolFieldValueByIdentifier, getRecordDateFieldValueByIdentifier, getRecordFileFieldValueByIdentifier, getRecordGeoFieldValueByIdentifier, getRecordListLookupFieldValueByIdentifier, getRecordLookupFieldValueByIdentifier, getRecordNumberFieldValueByIdentifier, getRecordStrFieldValueByIdentifier, } from '../utils/recordhelpers';
import env from '../utils/env';
import { NoLocalUrlError } from '../utils/errors';
import { isOnline } from '../utils/online';
let counter = 0;
let recordCounter = 0;
export function handleBoolInput(newValue, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const boolVal = recordRefCopy.allBoolValues.find(boolVal => identifier === boolVal.identifier);
    if (boolVal) {
        boolVal.value = newValue;
    }
    recordRef.value = recordRefCopy;
}
export function handleDateInput(newValue, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const dateVal = recordRefCopy.allDateValues.find(dateVal => identifier === dateVal.identifier);
    if (dateVal) {
        dateVal.value = newValue;
    }
    recordRef.value = recordRefCopy;
}
export async function handleFileInput(identifier, file, recordRef, localImageMap) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const fileVal = recordRefCopy.allFileValues.find(fileVal => identifier === fileVal.identifier);
    if (fileVal && file) {
        try {
            const fileUrl = `idb://${Date.now()}`;
            await storeFile({
                fileName: file.name,
                mimetype: file.type,
                data: file,
                url: fileUrl
            });
            fileVal.value = {
                createDate: new Date().toISOString(),
                filename: file.name,
                url: fileUrl,
                mimetype: file.type,
            };
            if (file.type.match(/image\/((jpe?g)|png)/)) {
                const imageUrl = URL.createObjectURL(file);
                localImageMap.value.set(fileUrl, imageUrl);
                localImageMap.value = new Map(localImageMap.value);
            }
        }
        catch (error) {
            if (error instanceof NoLocalUrlError) {
                console.info('overwrite file');
            }
            else {
                console.error(error);
            }
        }
    }
    else if (fileVal) {
        fileVal.value = undefined;
    }
    recordRef.value = recordRefCopy;
}
export function handleListLookupInput(keys, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const listLookupVal = recordRefCopy.allListLookupValues.find(listLookupVal => identifier === listLookupVal.identifier);
    if (listLookupVal) {
        listLookupVal.lookups = keys.map(key => ({ key, label: '' }));
    }
    recordRef.value = recordRefCopy;
}
export function handleLookupInput(key, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const lookupVal = recordRefCopy.allLookupValues.find(lookupVal => identifier === lookupVal.identifier);
    if (lookupVal) {
        lookupVal.lookup = { key, label: '' };
    }
    recordRef.value = recordRefCopy;
}
export function handleNumberInput(newValue, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const numVal = recordRefCopy.allNumberValues.find(numVal => identifier === numVal.identifier);
    if (numVal) {
        numVal.valueFloat = newValue;
    }
    recordRef.value = recordRefCopy;
}
export function handleStringInput(newValue, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const strVal = recordRefCopy.allStringValues.find(strVal => identifier === strVal.identifier);
    if (strVal) {
        strVal.value = newValue;
    }
    recordRef.value = recordRefCopy;
}
export async function handleGeoInput(newValue, identifier, recordRef) {
    const recordRefCopy = JSON.parse(JSON.stringify(recordRef.value));
    const geoVal = recordRefCopy.allGeoValues.find(geoVal => identifier === geoVal.identifier);
    if (geoVal) {
        geoVal.value = newValue;
    }
    recordRef.value = recordRefCopy;
}
export default defineComponent({
    props: {
        record: {
            type: Object,
        },
        controls: {
            type: Object,
        },
    },
    emits: ['llRemove', 'llCreate'],
    setup(props, { emit }) {
        let initialRecord = JSON.parse(JSON.stringify(props.record));
        let mutatedInitialRecord;
        const currentRecord = ref(initialRecord);
        const controls = toRef(props, 'controls');
        const showsMutated = ref(false);
        const hasMutated = ref(false);
        const viewAllFields = ref(false);
        const localImageMap = ref(new Map());
        const recordCount = recordCounter++;
        const isModified = computed((() => {
            return JSON.stringify(currentRecord.value) !== JSON.stringify(initialRecord);
        }));
        onMounted(async () => {
            try {
                const mutatedRec = await getRecordMutation(currentRecord.value.id);
                mutatedInitialRecord = mutatedRec;
                if (mutatedRec) {
                    counter++;
                    currentRecord.value = mutatedRec;
                    hasMutated.value = true;
                    showsMutated.value = true;
                }
            }
            catch (err) {
                console.error(err);
            }
        });
        function resetRecordRef() {
            if (showsMutated.value)
                currentRecord.value = mutatedInitialRecord;
            else
                currentRecord.value = initialRecord;
            counter++;
        }
        async function sendRecord(record) {
            try {
                const recordRefCopy = JSON.parse(JSON.stringify(record));
                await storeRecordMutation(recordRefCopy);
                mutatedInitialRecord = recordRefCopy;
                currentRecord.value = mutatedInitialRecord;
                hasMutated.value = true;
                showsMutated.value = true;
                await mutateRecord(recordRefCopy);
                await removeRecordMutation(recordRefCopy.id);
                await updateRecord(recordRefCopy);
                initialRecord = JSON.parse(JSON.stringify(recordRefCopy));
                hasMutated.value = false;
                showsMutated.value = false;
            }
            catch (err) {
                console.error(err);
            }
        }
        async function handleDeleteRecord(recordId, appId) {
            try {
                if (isOnline.value) {
                    const loading = await loadingController.create({});
                    await loading.present();
                    await deleteRecordMutation(recordId, appId);
                    await deleteRecord(recordId);
                    emit('llRemove', recordId);
                    await loading.dismiss();
                }
                else {
                    const alert = await alertController.create({
                        message: 'Einträge können nur gelöscht werden wenn das '
                            + 'Gerät eine Internetverbindung hat',
                        buttons: [{ text: 'ok' }]
                    });
                    await alert.present();
                }
            }
            catch (error) {
                console.error(error);
            }
        }
        return () => {
            const items = controls.value
                .filter(ctrl => ctrl.visible || viewAllFields.value)
                .flatMap(ctrl => {
                if (ctrl.type === 'bool') {
                    return h(IonItem, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => [
                        h(IonLabel, { position: 'stacked' }, () => [
                            ctrl.label,
                        ]),
                        h(IonToggle, {
                            key: `${ctrl.identifier}counter`,
                            slot: 'end',
                            checked: getRecordBoolFieldValueByIdentifier(ctrl.identifier, currentRecord.value),
                            onIonChange: (evt) => handleBoolInput(evt.target.checked, ctrl.identifier, currentRecord),
                        }),
                    ]);
                }
                else if (ctrl.type === 'date') {
                    return h(IonItem, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => [
                        h(IonLabel, { position: 'fixed' }, () => [
                            ctrl.label,
                        ]),
                        h(IonDatetime, {
                            key: `${ctrl.identifier}`,
                            slot: 'end',
                            displayFormat: ctrl.subtype === 'datetimeminute'
                                ? 'DD.MM.YYYY HH:mm'
                                : ctrl.subtype === 'datetimesecond'
                                    ? 'DD.MM.YYYY HH:mm ss'
                                    : 'DD.MM.YYYY',
                            onIonChange: (evt) => handleDateInput(evt.detail.value, ctrl.identifier, currentRecord),
                            value: getRecordDateFieldValueByIdentifier(ctrl.identifier, currentRecord.value),
                        }),
                    ]);
                }
                else if (ctrl.type === 'file') {
                    const fileValue = getRecordFileFieldValueByIdentifier(ctrl.identifier, currentRecord.value);
                    const imageUrl = (fileValue === null || fileValue === void 0 ? void 0 : fileValue.url.startsWith('idb://')) ? localImageMap.value.get(fileValue === null || fileValue === void 0 ? void 0 : fileValue.url)
                        : `https://my.living-apps.de${fileValue === null || fileValue === void 0 ? void 0 : fileValue.url}`;
                    return h(IonItemGroup, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => {
                        var _a, _b;
                        return [
                            ((_a = fileValue === null || fileValue === void 0 ? void 0 : fileValue.mimetype) === null || _a === void 0 ? void 0 : _a.match(/image\/((jpe?g)|png)/)) && isOnline.value
                                ?
                                    h(IonItem, { lines: 'none', key: `${ctrl.identifier}-preview` }, () => [
                                        h(IonImg, {
                                            src: imageUrl,
                                        }),
                                    ])
                                : undefined,
                            h(Fileupload, {
                                key: `${ctrl.identifier}${recordCount}${counter}`,
                                label: ctrl.label,
                                fileName: (_b = getRecordFileFieldValueByIdentifier(ctrl.identifier, currentRecord.value)) === null || _b === void 0 ? void 0 : _b.filename,
                                onLlFile: (file) => handleFileInput(ctrl.identifier, file, currentRecord, localImageMap)
                            }),
                        ];
                    });
                }
                else if (ctrl.type === 'geo') {
                    if (!isOnline.value || !env.googleApiKey) {
                        return h(IonItem, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => [
                            h(IonLabel, { position: 'stacked' }, () => [ctrl.label]),
                            h(IonInput, { disabled: true, value: `Nicht ${!isOnline.value ? 'offline' : ''} verfügbar.` }),
                        ]);
                    }
                    else {
                        const geoValue = getRecordGeoFieldValueByIdentifier(ctrl.identifier, currentRecord.value);
                        return h(GeoInput, {
                            key: `${ctrl.identifier}${recordCount}${counter}`,
                            label: ctrl.label,
                            info: geoValue === null || geoValue === void 0 ? void 0 : geoValue.info,
                            recordRef: currentRecord,
                            latLng: (geoValue === null || geoValue === void 0 ? void 0 : geoValue.lat) && geoValue.lng
                                ? [geoValue === null || geoValue === void 0 ? void 0 : geoValue.lat, geoValue === null || geoValue === void 0 ? void 0 : geoValue.lng]
                                : undefined,
                            identifier: ctrl.identifier,
                        });
                    }
                }
                else if (ctrl.type === 'number') {
                    return h(IonItem, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => [
                        h(IonLabel, { position: 'stacked' }, () => [
                            ctrl.label
                        ]),
                        h(IonInput, {
                            type: 'number',
                            key: `${ctrl.identifier}${recordCount}${counter}`,
                            onIonInput: (evt) => handleNumberInput(Number(evt.target.value), ctrl.identifier, currentRecord),
                            value: getRecordNumberFieldValueByIdentifier(ctrl.identifier, currentRecord.value),
                        }),
                    ]);
                }
                else if (ctrl.type === 'lookup') {
                    return h(IonItem, { key: `${ctrl.identifier}${recordCount}${counter}`, }, () => {
                        var _a;
                        return [
                            h(IonLabel, { position: 'stacked' }, () => [
                                ctrl.label,
                            ]),
                            h(IonSelect, {
                                key: `${ctrl.identifier}${recordCount}${counter}`,
                                onIonChange: (evt) => handleLookupInput(evt.target.value, ctrl.identifier, currentRecord),
                                value: (_a = getRecordLookupFieldValueByIdentifier(ctrl.identifier, currentRecord.value)) === null || _a === void 0 ? void 0 : _a.key,
                            }, () => [
                                ctrl.lookupData.map(({ key, label }) => (h(IonSelectOption, {
                                    value: key,
                                }, () => [label])))
                            ])
                        ];
                    });
                }
                else if (ctrl.type === 'multiplelookup') {
                    return h(IonItem, {}, () => {
                        var _a;
                        return [
                            h(IonLabel, { position: 'stacked' }, () => [
                                ctrl.label,
                            ]),
                            h(IonSelect, {
                                multiple: true,
                                key: `${ctrl.identifier}${recordCount}${counter}`,
                                onIonChange: (evt) => handleListLookupInput(evt.target.value, ctrl.identifier, currentRecord),
                                value: (_a = getRecordListLookupFieldValueByIdentifier(ctrl.identifier, currentRecord.value)) === null || _a === void 0 ? void 0 : _a.keys,
                            }, () => [
                                ctrl.lookupData.map(({ key, label }) => (h(IonSelectOption, {
                                    value: key,
                                }, () => [label])))
                            ])
                        ];
                    });
                }
                else if (ctrl.type === 'string') {
                    return h(IonItem, {}, () => [
                        h(IonLabel, { position: 'stacked' }, () => [
                            ctrl.label,
                        ]),
                        ctrl.subtype === 'textarea'
                            ? h(IonTextarea, {
                                autogrow: true,
                                key: `${ctrl.identifier}${recordCount}${counter}`,
                                onIonChange: (evt) => handleStringInput(evt.target.value, ctrl.identifier, currentRecord),
                                value: getRecordStrFieldValueByIdentifier(ctrl.identifier, currentRecord.value),
                            })
                            : h(IonInput, {
                                key: `${ctrl.identifier}${recordCount}${counter}`,
                                onIonInput: (evt) => handleStringInput(evt.target.value, ctrl.identifier, currentRecord),
                                value: getRecordStrFieldValueByIdentifier(ctrl.identifier, currentRecord.value),
                            }),
                    ]);
                }
                else {
                    return h(IonItem, {}, () => [
                        h(IonLabel, {}, () => [
                            ctrl.label
                        ]),
                        h(IonInput, {
                            slot: 'end',
                            value: 'Noch nicht unterstützt',
                            disabled: true,
                        })
                    ]);
                }
            });
            return h(IonCard, {}, () => [
                h(IonCardContent, {}, () => [
                    hasMutated.value || controls.value.reduce((prev, curr) => (curr.visible ? 1 : 0) + prev, 0) !== controls.value.length
                        ?
                            h(IonItem, () => [
                                hasMutated.value
                                    ? h(IonButton, {
                                        onClick: () => {
                                            counter++;
                                            showsMutated.value = !showsMutated.value;
                                            currentRecord.value = showsMutated.value
                                                ? mutatedInitialRecord
                                                : initialRecord;
                                        },
                                        fill: 'clear',
                                    }, () => [
                                        h(IonIcon, {
                                            icon: showsMutated.value ? cloudOfflineOutline : cloudCircleOutline,
                                        })
                                    ])
                                    : undefined,
                                controls.value.reduce((prev, curr) => (curr.visible ? 1 : 0) + prev, 0) !== controls.value.length
                                    ?
                                        h(IonButton, {
                                            onClick: () => {
                                                viewAllFields.value = !viewAllFields.value;
                                            },
                                            fill: 'clear',
                                            slot: 'end'
                                        }, () => [
                                            viewAllFields.value
                                                ? 'Felder ausblenden'
                                                : 'Alle Felder anzeigen'
                                        ])
                                    : undefined
                            ])
                        : undefined,
                    ...items,
                    currentRecord.value.id.startsWith('tmp')
                        ?
                            h(IonItemDivider, {}, () => [
                                h(IonButton, {
                                    onClick: () => emit('llRemove', currentRecord.value.id),
                                    fill: 'clear',
                                    slot: 'start'
                                }, () => 'verwerfen'),
                                h(IonButton, {
                                    onClick: () => emit('llCreate', currentRecord.value),
                                    fill: 'clear',
                                    slot: 'end'
                                }, () => 'Abschicken'),
                            ])
                        :
                            h(IonItemDivider, {
                                style: ''
                            }, () => [
                                !isModified.value
                                    ?
                                        h(IonButton, {
                                            fill: 'clear',
                                            onClick: () => { var _a, _b, _c, _d; return handleDeleteRecord((_b = (_a = props.record) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '', (_d = (_c = props.record) === null || _c === void 0 ? void 0 : _c.app.id) !== null && _d !== void 0 ? _d : ''); },
                                            color: isOnline.value ? 'primary' : 'medium',
                                        }, () => 'löschen')
                                    :
                                        [
                                            currentRecord.value.id.startsWith('loc_')
                                                ? undefined
                                                :
                                                    h(IonButton, {
                                                        onClick: () => resetRecordRef(),
                                                        fill: 'clear',
                                                        slot: 'start'
                                                    }, () => 'Zurücksetzen'),
                                            h(IonButton, {
                                                onClick: () => sendRecord(currentRecord.value),
                                                fill: 'clear',
                                                slot: 'end',
                                            }, () => 'Abschicken'),
                                        ],
                            ])
                ]),
            ]);
        };
    }
});
