export function createLocalRecord(app) {
    const newRecord = {
        app: {
            id: app.id,
        },
        allAppLookups: [],
        allBoolValues: [],
        allDateValues: [],
        allFileValues: [],
        allGeoValues: [],
        allListAppLookupValues: [],
        allListLookupValues: [],
        allLookupValues: [],
        allNumberValues: [],
        allStringValues: [],
        id: `tmp_${Date.now()}`,
        createDate: new Date().toString(),
    };
    for (const ctrl of app.controls) {
        switch (ctrl.type) {
            case 'string':
                newRecord.allStringValues.push({
                    identifier: ctrl.identifier,
                    subtype: ctrl.subtype
                });
                break;
            case 'number':
                newRecord.allNumberValues.push({
                    identifier: ctrl.identifier,
                });
                break;
            case 'lookup':
                newRecord.allLookupValues.push({
                    identifier: ctrl.identifier
                });
                break;
            case 'multiplelookup':
                newRecord.allListLookupValues.push({
                    identifier: ctrl.identifier,
                    lookups: [],
                });
                break;
            case 'multipleapplookup':
                newRecord.allListAppLookupValues.push({
                    identifier: ctrl.identifier,
                    records: [],
                });
                break;
            case 'geo':
                newRecord.allGeoValues.push({
                    identifier: ctrl.identifier,
                });
                break;
            case 'file':
                newRecord.allFileValues.push({
                    identifier: ctrl.identifier,
                });
                break;
            case 'date':
                newRecord.allDateValues.push({
                    identifier: ctrl.identifier,
                });
                break;
            case 'bool':
                newRecord.allBoolValues.push({
                    identifier: ctrl.identifier,
                    value: false
                });
                break;
            case 'applookup':
                newRecord.allAppLookups.push({
                    identifier: ctrl.identifier,
                });
                break;
        }
    }
    return newRecord;
}
